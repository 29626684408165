.pagination-panel {
  color: rgba(0, 0, 0, 0.54);
  margin: 10px;
  font-size: 0.8em;
}

.pagination-size-option {
    .pagination-select > div > div {
        padding-right: 20px;
        padding-left: 8px;
    }
}

.pagination-section {
  margin-right: 36px;
}

.pagination-button {
  font-size: 1em !important;
  width: 32px !important;
  height: 32px !important;
  &.selected {
    font-weight: 700 !important;
  }
}