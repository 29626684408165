.rt-th-asc-indicator {
  display: none !important;
}

.rt-th-desc-indicator {
  display: none !important;
}

.rt-th-asc-indicator.-sort-asc {
  display: inherit !important;
}

.rt-th-desc-indicator.-sort-desc {
  display: inherit !important;
}